export default {
  button: {
    primary: {
      base: "text-white bg-blue-600 border border-transparent",
      active:
        "active:bg-blue-600 hover:bg-blue-700 focus:ring focus:ring-blue-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
  },
};
